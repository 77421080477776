import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  reportData: [],
  bankData: [],
  modeData: [],
  error: null,
  message: null,
}

const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder

      // Fetch user Report data
      .addCase(fetchReportData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchReportData.fulfilled, (state, { payload: { result, error } }) => {
        state.loading = false
        if (result) {
          state.reportData = result
        } else {
          state.error = error
        }
      })
      .addCase(fetchReportData.rejected, (state, action) => {
        state.error = 'Something went wrong with the server'
      })


      // Fetch bank data
      .addCase(fetchBankData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchBankData.fulfilled, (state, { payload: { result, error } }) => {
        state.loading = false
        if (result) {
          state.bankData = result
        } else {
          state.error = error
        }
      })
      .addCase(fetchBankData.rejected, (state, action) => {
        state.error = 'Something went wrong with the server'
      })


      // Fetch Mode data
      .addCase(fetchModeData.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchModeData.fulfilled, (state, { payload: { result, error } }) => {
        state.loading = false
        if (result) {
          state.modeData = result
        } else {
          state.error = error
        }
      })
      .addCase(fetchModeData.rejected, (state, action) => {
        state.error = 'Something went wrong with the server'
      })

    
  },
})

export const fetchReportData = createAsyncThunk('fetch/reports', async (filter) => {
  const response = await fetch(
    process.env.REACT_APP_BASE_API_URL + `/reports/report_filter`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
      body: JSON.stringify(filter),
    },
  )
  const result = await response.json()
  return result
})


export const fetchBankData = createAsyncThunk('fetch/bank_report', async (filter) => {
  const response = await fetch(
    process.env.REACT_APP_BASE_API_URL + `/reports/report_bank`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
      body: JSON.stringify(filter),
    },
  )
  const result = await response.json()
  return result
})


export const fetchModeData = createAsyncThunk('fetch/payment_mode', async (filter) => {
  const response = await fetch(
    process.env.REACT_APP_BASE_API_URL + `/reports/report_mode`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
      body: JSON.stringify(filter),
    },
  )
  const result = await response.json()
  return result
})

export const fetchLeadData = createAsyncThunk('fetch/lead_mode', async (filter) => {
  const response = await fetch(
    process.env.REACT_APP_BASE_API_URL + `/reports/report_leads`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
      body: JSON.stringify(filter),
    },
  )
  const result = await response.json()
  return result
})

export const fetchCallLogsData = createAsyncThunk('fetch/lead_mode', async (filter) => {
  const response = await fetch(
    process.env.REACT_APP_BASE_API_URL + `/reports/report_call_logs`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
      body: JSON.stringify(filter),
    },
  )
  const result = await response.json()
  return result
})



export const {} = reportSlice.actions
export default reportSlice.reducer
